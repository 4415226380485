import { Injectable } from '@angular/core';
import { AuthenticationSelectors } from '@features/authentification/domain/redux/selectors/authentication.selectors';
import { BuyerDetailInterface } from '@features/buyers/domain/entities/buyers.interface';
import { MandatDetailInterface } from '@features/mandats/domain/entities/mandat-detail.interface';
import { MandatInterface } from '@features/mandats/domain/entities/mandat.interface';
import { VisitApiService } from '@features/visit-form/adapters/secondary/real/visit-api.service';
import {
  AddAcquereurToVisitForm,
  AddMandatToVisitForm,
  CreateVisit,
  RemoveAcquereurFromVisitForm,
  RemoveMandatFromVisitForm,
  ResetVisitForm,
} from '@features/visit-form/domain/redux/actions/visit-form.actions';
import { SweetAlertService } from '@libs/sweet-alert/sweet-alert.service';
import { Action, Select, State, StateContext } from '@ngxs/store';
import { environment } from '@src/environments/environment';
import { DateFormatter } from '@src/shared/utils/date-formater';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { VisiteInterface } from '@features/visites/domain/models/visite-interface';

interface BodyVisite {
  mandats: MandatInterface[];
  acquereurs: BuyerDetailInterface[];
  date_visite: string;
  id_consultant: number;
}

export class VisitFormStateModel {
  mandats!: MandatDetailInterface[];
  acquereurs!: BuyerDetailInterface[];
}

export const defaultVisitFormState: VisitFormStateModel = {
  mandats: [],
  acquereurs: [],
};

@State<VisitFormStateModel>({
  name: 'visitForm',
  defaults: defaultVisitFormState,
})
@Injectable()
export class VisitFormState {
  @Select(AuthenticationSelectors.userId) userId$!: Observable<number>;

  constructor(
    private api: VisitApiService,
    private sweetAlertService: SweetAlertService
  ) {}

  @Action(AddMandatToVisitForm)
  async addMandatToVisitForm(
    ctx: StateContext<VisitFormStateModel>,
    action: AddMandatToVisitForm
  ): Promise<void> {
    try {
      const state = ctx.getState();
      const mandatToAdd = { ...action.mandat };
      if (
        !state.mandats.some(
          mandat => mandat.mandat.id === action.mandat.mandat.id
        )
      ) {
        ctx.patchState({
          mandats: [...state.mandats, mandatToAdd],
        });
        this.sweetAlertService.showToastSuccess(
          'Le mandat a bien été ajouter au bon de visite'
        );
      }
    } catch (error) {
      console.error(
        'An error occurred while adding the Mandat to Visit Form:',
        error
      );
      this.sweetAlertService.showToastError(
        "Une erreur est survenue lors de l'ajout du mandat à la visite"
      );
    }
  }

  @Action(RemoveMandatFromVisitForm)
  async removeMandatFromVisitForm(
    ctx: StateContext<VisitFormStateModel>,
    action: RemoveMandatFromVisitForm
  ): Promise<void> {
    try {
      const state = ctx.getState();
      ctx.patchState({
        mandats: state.mandats.filter(
          mandat => mandat.mandat.id !== action.mandatId
        ),
      });
      this.sweetAlertService.showToastSuccess(
        'Le mandat a bien été supprimé du bon de visite'
      );
    } catch (error) {
      console.error(
        'An error occurred while removing the Mandat from Visit Form:',
        error
      );
      this.sweetAlertService.showToastError(
        'Un beug est survenue lors de la suppression du mandat de la visite'
      );
    }
  }

  @Action(AddAcquereurToVisitForm)
  addAcquereurToVisitForm(
    ctx: StateContext<VisitFormStateModel>,
    action: AddAcquereurToVisitForm
  ): void {
    const state = ctx.getState();
    const acquereurToAdd = { ...action.buyer };
    if (!state.acquereurs.some(acquereur => acquereur.id === action.buyer.id)) {
      ctx.patchState({
        acquereurs: [...state.acquereurs, acquereurToAdd],
      });
    }
  }

  @Action(ResetVisitForm)
  resetVisitForm(ctx: StateContext<VisitFormStateModel>): void {
    ctx.setState(defaultVisitFormState);
  }

  @Action(RemoveAcquereurFromVisitForm)
  removeAcquereurFromVisitForm(
    ctx: StateContext<VisitFormStateModel>,
    action: RemoveAcquereurFromVisitForm
  ): void {
    const state = ctx.getState();
    ctx.patchState({
      acquereurs: state.acquereurs.filter(
        acquereur => acquereur.id !== action.acquereurId
      ),
    });
  }

  @Action(CreateVisit)
  createVisit(ctx: StateContext<VisitFormStateModel>): void {
    const state = ctx.getState();
    // take only mandat in mandat details interface so mandats.mandat
    const mandats: MandatInterface[] = state.mandats.map(mandat => ({
      ...mandat.mandat,
      enseigne: mandat.salePoints.enseigne,
    }));
    this.userId$.subscribe(userId => {
      const body = {
        acquereurs: state.acquereurs,
        mandats: mandats,
        date_visite: DateFormatter.todayDateYYYYMMDD(),
        id_consultant: userId, // Utiliser l'ID de userId
      };

      this.api
        .create(body)
        .pipe(
          catchError(error => {
            console.error(
              'Une erreur est survenue lors de la création de la visite',
              error
            );
            return throwError(error);
          })
        )
        .subscribe(visit => {
          this.generateAndOpenPDF(visit, body); // passer l'id de l'utilisateur à la fonction
        });
    });
  }

  generateAndOpenPDF(visit: VisiteInterface, body: BodyVisite) {
    console.log('Visite créée', visit);

    const id_mandat = body.mandats.map(mandat => mandat.id);
    const id_buyer = body.acquereurs.map(acquereur => acquereur.id);

    const url =
      `${environment.apiUrl}/pdf/visite?id_consultant=${body.id_consultant}` +
      `&mandats=${id_mandat.join(',')}&buyers=${id_buyer.join(',')}`;
    window.open(url);
  }
}
